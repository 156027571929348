import { handleError } from '@pn/core/errors/handleError';
import { apiIntelDynamicDataProvider } from '@pn/services/api/intel-data/apiIntelDataProvider';
import { useDebouncedValue } from '@pn/ui/hooks/useDebouncedValue';
import React from 'react';
import { intelQueriesActions, useIntelQueriesStorage } from 'src/storage';
import { IntelQuery } from '@pn/core/domain/intel/intel-activity-item';
import { useIntelAccess } from '@pn/core/permissions/isIntelAccessDenied';

const tokensById: Record<string, number> = {};

function getIntelData(
  intelQuery: IntelQuery,
  geographyFilter: GeoJSON.Geometry | null,
  province: string | null
) {
  const { id: intelQueryId, query } = intelQuery;
  if (!tokensById[intelQueryId]) {
    tokensById[intelQueryId] = 0;
  }
  const thisToken = ++tokensById[intelQueryId];

  intelQueriesActions().request(intelQueryId);

  switch (query.dataType) {
    case 'wells_by_liquid_production':
      apiIntelDynamicDataProvider
        .getTopWells(query, geographyFilter, province, 'liquid', 30)
        .then((data) => {
          intelQueriesActions().receive(intelQueryId, data);
        })
        .catch((error) => {
          handleError({
            error,
            onError: () => intelQueriesActions().error(intelQueryId),
            userFriendlyMessage: `Failed to get wells by liquid production`,
          });
        });
      break;
    case 'wells_by_gas_production':
      apiIntelDynamicDataProvider
        .getTopWells(query, geographyFilter, province, 'gas', 30)
        .then((data) => {
          intelQueriesActions().receive(intelQueryId, data);
        })
        .catch((error) => {
          handleError({
            error,
            onError: () => intelQueriesActions().error(intelQueryId),
            userFriendlyMessage: `Failed to get wells by gas production`,
          });
        });
      break;
    case 'wells_by_production':
      apiIntelDynamicDataProvider
        .getTopWells(query, geographyFilter, province, null, 30)
        .then((data) => {
          intelQueriesActions().receive(intelQueryId, data);
        })
        .catch((error) => {
          handleError({
            error,
            onError: () => intelQueriesActions().error(intelQueryId),
            userFriendlyMessage: `Failed to get wells by production`,
          });
        });
      break;
    case 'well_licence_mismatches':
      apiIntelDynamicDataProvider
        .getWellMismatches(query, geographyFilter, province)
        .then((data) => {
          intelQueriesActions().receive(intelQueryId, data);
        })
        .catch((error) => {
          handleError({
            error,
            onError: () => intelQueriesActions().error(intelQueryId),
            userFriendlyMessage: `Failed to get mismatched wells`,
          });
        });
      break;
    default:
      apiIntelDynamicDataProvider
        .getData(intelQuery, geographyFilter, province)
        .then((data) => {
          if (thisToken !== tokensById[intelQueryId]) {
            throw new Error('Not the latest request for this data type');
          }

          intelQueriesActions().receive(intelQueryId, data);
        })
        .catch((error) => {
          if (error.message !== 'Not the latest request for this data type') {
            handleError({
              error,
              onError: () => intelQueriesActions().error(intelQueryId),
              userFriendlyMessage: `Failed to get ${query.dataType}`,
            });
          } else {
            // console.log('Ignored outdated fetch result for', dataType);
          }
        });
      break;
  }
}

export function useAutoLoadIntelData() {
  const { queriesToProcess, areaGeometryFilter, provinceFilter } =
    useIntelQueriesStorage();

  const access = useIntelAccess();

  const [debouncedQueriesToProcess] = useDebouncedValue(queriesToProcess, 500);

  const queriesUserHasAccessTo = React.useMemo(() => {
    return debouncedQueriesToProcess.filter((query) =>
      access(query.query.dataType).granted()
    );
  }, [access, debouncedQueriesToProcess]);

  React.useEffect(() => {
    queriesUserHasAccessTo.forEach((intelQuery) => {
      getIntelData(intelQuery, areaGeometryFilter, provinceFilter);
    });
  }, [areaGeometryFilter, queriesUserHasAccessTo, provinceFilter]);
}
