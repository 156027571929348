import { handleError } from '@pn/core/errors/handleError';
import { apiCompanyInfoProvider } from '@pn/services/api/intel/apiCompanyInfoProvider';
import React from 'react';
import { mergersAndAcquisitionsActions } from 'src/storage';

export async function getMergersAndAcquisitions() {
  try {
    mergersAndAcquisitionsActions().request();

    const mergersAndAcquisitions =
      await apiCompanyInfoProvider.getMergersAndAcquisitions();

    mergersAndAcquisitionsActions().receive(mergersAndAcquisitions);
  } catch (error) {
    handleError({
      error,
      onError: mergersAndAcquisitionsActions().error,
      userFriendlyMessage: 'Failed to get mergers and acquisitions',
    });
  }
}

export function useAutoLoadMergersAndAcquisitions() {
  React.useEffect(() => {
    getMergersAndAcquisitions();
  }, []);
}
