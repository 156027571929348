import {
  buildIntelQueryObject,
  createIntelQuery,
  IntelQuery,
} from '@pn/core/domain/intel/intel-activity-item';
import {
  IntelReport,
  ProcessedIntelReportFilters,
  processIntelReportFilters,
} from '@pn/core/domain/intel/intel-report.ts';
import { isEmpty, isNil } from 'lodash-es';
import { Filter } from '@pn/core/domain/query.ts';
import { generateWellMismatches } from './report-defaults/wellMismatches.ts';

export type ReportFiltersAndId = ProcessedIntelReportFilters & {
  linkedReportId: string;
};

export function generateIntelQueriesForReport(
  report: IntelReport,
  companyBrandNameRecords: Record<string, string>,
  landSaleDate: string
): IntelQuery[] {
  const processedFilters: ReportFiltersAndId = {
    ...processIntelReportFilters(report.filters, companyBrandNameRecords),
    linkedReportId: report.id,
  };

  const { startDate, endDate, geoJSONGeometry } = processedFilters;

  const intelQueries: IntelQuery[] = [
    ...generateWellQueries(processedFilters),
    ...generateAERQueries(processedFilters),
    ...generateMRTransfersQueries(processedFilters),
    ...generateLicenceTransfersQueries(processedFilters),
    ...generateWellMismatches(processedFilters),
  ];

  if (report.filters.type === 'Area') {
    // land is only possible for area reports (for now)
    const newLandPostingsQuery = createIntelQuery({
      query: buildIntelQueryObject('land_postings', {
        filters: [
          {
            field: 'notice_date',
            operator: 'isAnyOf',
            value: landSaleDate,
            unitSystem: undefined,
          },
          {
            field: 'geom',
            operator: 'intersects',
            value: geoJSONGeometry as any,
            unitSystem: undefined,
          },
        ],
      }),
      dataType: 'grids',
      linkedReportId: report.id,
    });
    intelQueries.push(newLandPostingsQuery);
    const newLandResultsQuery = createIntelQuery({
      query: buildIntelQueryObject('land_results', {
        filters: [
          {
            field: 'notice_date',
            operator: '>=',
            value: startDate,
            unitSystem: undefined,
          },
          {
            field: 'notice_date',
            operator: '<=',
            value: endDate,
            unitSystem: undefined,
          },
          {
            field: 'geom',
            operator: 'intersects',
            value: geoJSONGeometry as any,
            unitSystem: undefined,
          },
        ],
      }),
      dataType: 'grids',
      linkedReportId: report.id,
    });
    intelQueries.push(newLandResultsQuery);
  }

  return intelQueries;
}

function generateLicenceTransfersQueries({
  startDate,
  endDate,
  companyBrandName,
  formation,
  fieldName,
  geoJSONGeometry,
  linkedReportId,
}: ReportFiltersAndId) {
  const baseLicenceTransfersFilters: Filter[] = [
    {
      field: 'transfer_date',
      operator: '>=',
      value: startDate,
      unitSystem: undefined,
    },
    {
      field: 'transfer_date',
      operator: '<=',
      value: endDate,
      unitSystem: undefined,
    },
  ];

  if (!isNil(geoJSONGeometry)) {
    baseLicenceTransfersFilters.push({
      field: 'geom',
      operator: 'intersects',
      value: geoJSONGeometry as any,
      unitSystem: undefined,
    });
  }

  if (!isEmpty(formation) || !isEmpty(fieldName)) return []; // no licence transfers fields/formations

  if (isEmpty(companyBrandName)) {
    return [
      createIntelQuery({
        query: buildIntelQueryObject('well_license_transfers', {
          filters: baseLicenceTransfersFilters,
        }),
        dataType: 'wells',
        linkedReportId,
      }),
    ];
  } else {
    return [
      createIntelQuery({
        query: buildIntelQueryObject('well_license_transfers', {
          filters: [
            ...baseLicenceTransfersFilters,
            {
              field: 'from_company_brand.display_name',
              operator: '=',
              value: companyBrandName,
              unitSystem: undefined,
            },
          ],
        }),
        name: 'Licence Transfer From ' + companyBrandName,
        dataType: 'wells',
        linkedReportId,
      }),
      createIntelQuery({
        query: buildIntelQueryObject('well_license_transfers', {
          filters: [
            ...baseLicenceTransfersFilters,
            {
              field: 'to_company_brand.display_name',
              operator: '=',
              value: companyBrandName,
              unitSystem: undefined,
            },
          ],
        }),
        name: 'Licence Transfer To ' + companyBrandName,
        dataType: 'wells',
        linkedReportId,
      }),
    ];
  }
}

function generateMRTransfersQueries({
  startDate,
  endDate,
  companyBrandName,
  formation,
  fieldName,
  geoJSONGeometry,
  linkedReportId,
}: ReportFiltersAndId) {
  const baseMRTransfersFilters: Filter[] = [
    {
      field: 'created_at',
      operator: '>=',
      value: startDate,
      unitSystem: undefined,
    },
    {
      field: 'created_at',
      operator: '<=',
      value: endDate,
      unitSystem: undefined,
    },
  ];

  if (!isNil(geoJSONGeometry)) {
    baseMRTransfersFilters.push({
      field: 'geom',
      operator: 'intersects',
      value: geoJSONGeometry as any,
      unitSystem: undefined,
    });
  }

  if (!isEmpty(formation) || !isEmpty(fieldName)) return []; // no MR transfers fields/formations
  if (isEmpty(companyBrandName)) {
    return [
      createIntelQuery({
        query: buildIntelQueryObject('mineral_rights_transfers', {
          filters: baseMRTransfersFilters,
        }),
        dataType: 'mineral_rights',
        linkedReportId,
      }),
    ];
  } else {
    return [
      createIntelQuery({
        query: buildIntelQueryObject('mineral_rights_transfers', {
          filters: [
            ...baseMRTransfersFilters,
            {
              field: 'from_company_brand.display_name',
              operator: '=',
              value: companyBrandName,
              unitSystem: undefined,
            },
          ],
        }),
        name: 'MR Transfer From ' + companyBrandName,
        dataType: 'mineral_rights',
        linkedReportId,
      }),
      createIntelQuery({
        query: buildIntelQueryObject('mineral_rights_transfers', {
          filters: [
            ...baseMRTransfersFilters,
            {
              field: 'to_company_brand.display_name',
              operator: '=',
              value: companyBrandName,
              unitSystem: undefined,
            },
          ],
        }),
        name: 'MR Transfer To ' + companyBrandName,
        dataType: 'mineral_rights',
        linkedReportId,
      }),
    ];
  }
}

function generateAERQueries({
  startDate,
  endDate,
  formation,
  companyBrandName,
  fieldName,
  geoJSONGeometry,
  linkedReportId,
}: ReportFiltersAndId) {
  const baseAERTransfersFilters: Filter[] = [
    {
      field: 'last_status_date',
      operator: '>=',
      value: startDate,
      unitSystem: undefined,
    },
    {
      field: 'last_status_date',
      operator: '<=',
      value: endDate,
      unitSystem: undefined,
    },
  ];
  if (!isNil(geoJSONGeometry)) {
    baseAERTransfersFilters.push({
      field: 'geom',
      operator: 'intersects',
      value: geoJSONGeometry as any,
      unitSystem: undefined,
    });
  }

  if (!isEmpty(formation) || !isEmpty(fieldName)) return []; // no AER transfers fields/formations
  if (isEmpty(companyBrandName)) {
    return [
      createIntelQuery({
        query: buildIntelQueryObject('aer_transfers', {
          filters: baseAERTransfersFilters,
        }),
        dataType: 'grids',
        linkedReportId,
      }),
    ];
  } else {
    return [
      createIntelQuery({
        query: buildIntelQueryObject('aer_transfers', {
          filters: [
            ...baseAERTransfersFilters,
            {
              field: 'from_company_brand.display_name',
              operator: 'isAnyOf',
              value: companyBrandName,
              unitSystem: undefined,
            },
          ],
        }),
        name: 'AER Transfer From ' + companyBrandName,
        dataType: 'grids',
        linkedReportId,
      }),
      createIntelQuery({
        query: buildIntelQueryObject('aer_transfers', {
          filters: [
            ...baseAERTransfersFilters,
            {
              field: 'to_company_brand.display_name',
              operator: 'isAnyOf',
              value: companyBrandName,
              unitSystem: undefined,
            },
          ],
        }),
        name: 'AER Transfer To ' + companyBrandName,
        dataType: 'grids',
        linkedReportId,
      }),
    ];
  }
}

function generateWellQueries({
  startDate,
  endDate,
  formation,
  companyBrandName,
  fieldName,
  geoJSONGeometry,
  linkedReportId,
}: ReportFiltersAndId) {
  const wellLicencesFilters: Filter[] = [
    {
      field: 'licenses.license_date',
      operator: '>=',
      value: startDate,
      unitSystem: undefined,
    },
    {
      field: 'licenses.license_date',
      operator: '<=',
      value: endDate,
      unitSystem: undefined,
    },
  ];

  const wellSpudsFilters: Filter[] = [
    {
      field: 'last_spud_date',
      operator: '>=',
      value: startDate,
      unitSystem: undefined,
    },
    {
      field: 'last_spud_date',
      operator: '<=',
      value: endDate,
      unitSystem: undefined,
    },
  ];

  if (!isNil(geoJSONGeometry)) {
    wellLicencesFilters.push({
      field: 'wellbores.geom',
      operator: 'intersects',
      value: geoJSONGeometry as any,
      unitSystem: undefined,
    });

    wellSpudsFilters.push({
      field: 'wellbores.geom',
      operator: 'intersects',
      value: geoJSONGeometry as any,
      unitSystem: undefined,
    });
  }

  if (!isNil(formation)) {
    wellLicencesFilters.push({
      field: 'wellbores.formation',
      operator: 'contains',
      value: formation,
      unitSystem: undefined,
    });

    wellSpudsFilters.push({
      field: 'wellbores.formation',
      operator: 'contains',
      value: formation,
      unitSystem: undefined,
    });
  }
  if (!isNil(companyBrandName)) {
    wellLicencesFilters.push({
      field: 'company_brand.display_name',
      operator: '=',
      value: companyBrandName,
      unitSystem: undefined,
    });
    wellSpudsFilters.push({
      field: 'company_brand.display_name',
      operator: '=',
      value: companyBrandName,
      unitSystem: undefined,
    });
  }

  if (!isNil(fieldName)) {
    wellLicencesFilters.push({
      field: 'wellbores.field_name',
      operator: 'ILIKE',
      value: fieldName,
      unitSystem: undefined,
    });
    wellSpudsFilters.push({
      field: 'wellbores.field_name',
      operator: 'ILIKE',
      value: fieldName,
      unitSystem: undefined,
    });
  }

  return [
    createIntelQuery({
      name: 'Licence Activity',
      query: buildIntelQueryObject('well_licenses', {
        filters: wellLicencesFilters,
      }),
      dataType: 'wells',
      linkedReportId,
    }),
    createIntelQuery({
      name: 'Spud Activity',
      query: buildIntelQueryObject('well_spuds', {
        filters: wellSpudsFilters,
      }),
      dataType: 'wells',
      linkedReportId,
    }),
  ];
}
