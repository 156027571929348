import React from 'react';

type UIMapType = {
  isSidePanelExpanded: boolean;
  isMobileDrawerOpen: boolean;
  handleToggleSidePanel: () => void;
  handleToggleDrawer: () => void;
  handleCloseDrawer: () => void;
};

function getStateFromLocalStorage(): boolean {
  return JSON.parse(localStorage.getItem('isSidePanelExpanded') || 'true');
}

export const UIStateContext = React.createContext<UIMapType>({
  isSidePanelExpanded: true,
  handleToggleSidePanel: () => {},
  isMobileDrawerOpen: false,
  handleToggleDrawer: () => {},
  handleCloseDrawer: () => {},
});

export const UIStateProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isSidePanelExpanded, setIsSidePanelExpanded] = React.useState(
    getStateFromLocalStorage()
  );
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = React.useState(false);

  const handleToggleDrawer = () =>
    setIsMobileDrawerOpen((prevState) => !prevState);
  const handleCloseDrawer = () => setIsMobileDrawerOpen(false);
  const handleToggleSidePanel = () =>
    setIsSidePanelExpanded((prevState) => !prevState);

  return (
    <UIStateContext.Provider
      value={{
        isSidePanelExpanded,
        isMobileDrawerOpen,
        handleToggleSidePanel,
        handleToggleDrawer,
        handleCloseDrawer,
      }}
    >
      {children}
    </UIStateContext.Provider>
  );
};

export const useUI = (): UIMapType => {
  const context = React.useContext(UIStateContext);
  if (!context) {
    throw new Error('useUI must be used within an UIStateProvider');
  }
  return context;
};
