import { pnApiClient } from '@pn/services/api/pnApiClient';
import { ApiIntelReport } from './types';
import { isEmpty } from 'lodash-es';
import { apiIntelReportMapper } from '@pn/services/api/intel-report/apiIntelReportsMapper.ts';
import { IIntelReportsProvider } from '@pn/core/providers/intel/ports.ts';

export const requestIntelReports = async (userId?: string) => {
  let apiIntelReports: ApiIntelReport[] = [];
  if (isEmpty(userId)) {
    apiIntelReports = await pnApiClient.request<ApiIntelReport[]>({
      url: `v1/market_intel/intel_reports`,
    });
  } else {
    // can do the admin version of the endpoint here? unsure
  }

  return apiIntelReports.map((report) =>
    apiIntelReportMapper.toDomainIntelReport(report)
  );
};

export const apiIntelReportsProvider: IIntelReportsProvider = {
  getIntelReports: requestIntelReports,
};
