import { useAutoLoadSelectedCompany } from '@pn/core/providers/intel/getCompanyExtraInfo.ts';
import { useAutoGetIntelAlerts } from '@pn/core/providers/intel/getIntelAlerts.ts';
import { useAutoLoadIntelData } from 'src/providers/intel-data/getData.ts';
import { useAutoLoadIntelStatistics } from '@pn/core/providers/intel/getIntel.ts';
import { useAutoLoadCompanies } from '@pn/core/providers/company/getCompanies.ts';
import { useAutoLoadMergersAndAcquisitions } from 'src/providers/intel-data/getMergersAndAcquisitions.ts';
import { useAutoGetIntelWorkspaceItems } from 'src/providers/getIntelWorkspaceItems.ts';
import { useAutoInitializeFreshDesk } from '@pn/services/support/useAutoInitializeFreshDesk.ts';
import { useLoadReportData } from 'src/providers/report/getReportData.ts';
import { useAutoLoadIntelReports } from '@pn/core/providers/intel/getReports.ts';
import { useIntelQueriesStorage } from 'src/storage';
import { useDebouncedValue } from '@pn/ui/hooks/useDebouncedValue.ts';
import React from 'react';
import { isEmpty } from 'lodash-es';
import { useWorkspaceStorage, workspaceActions } from '@pn/core/storage';
import { usePrevious } from '@pn/core/utils/hooks.ts';

export function Effects() {
  useAutoLoadSelectedCompany();
  useAutoGetIntelAlerts();
  useAutoLoadIntelData();
  useAutoLoadIntelStatistics();
  useAutoLoadCompanies();
  useAutoLoadMergersAndAcquisitions();
  useAutoGetIntelWorkspaceItems();

  useAutoInitializeFreshDesk('boe-intel');

  useAutoRemoveWorkspaceItems();
  useAutoDeleteWorkspaceItems();
  useLoadReportData();
  useAutoLoadIntelReports();
  return null;
}

function useAutoRemoveWorkspaceItems() {
  const { queriesToRemoveFromMap } = useIntelQueriesStorage();
  const [debouncedQueries] = useDebouncedValue(queriesToRemoveFromMap, 500);

  React.useEffect(() => {
    if (isEmpty(debouncedQueries)) return;
    debouncedQueries.forEach((intelQuery) => {
      workspaceActions().removeFromWorkspace(intelQuery.id);
    });
  }, [debouncedQueries]);
}

function useAutoDeleteWorkspaceItems() {
  const { queries } = useIntelQueriesStorage();
  const { allWorkspaceItems } = useWorkspaceStorage();
  const queryIds = React.useMemo(
    () => queries.map((query) => query.id),
    [queries]
  );

  const previousQueryIds = usePrevious(queryIds, []);
  const queryIdsThatNoLongerExist = React.useMemo(() => {
    return previousQueryIds.filter((id) => !queryIds.includes(id));
  }, [previousQueryIds, queryIds]);

  const allWorkspaceItemsToDelete = React.useMemo(() => {
    return allWorkspaceItems.filter((item) =>
      queryIdsThatNoLongerExist.includes(item.id)
    );
  }, [allWorkspaceItems, queryIdsThatNoLongerExist]);

  React.useEffect(() => {
    if (isEmpty(allWorkspaceItemsToDelete)) return;
    allWorkspaceItemsToDelete.forEach((workspaceItem) => {
      workspaceActions().remove(workspaceItem);
    });
  }, [allWorkspaceItemsToDelete]);
}
