import { Company } from '@pn/core/domain/intel/company';
import { handleError } from '@pn/core/errors/handleError';
import {
  extraCompanyInfoActions,
  useCompaniesStorage,
} from '@pn/core/storage';
import { apiCompanyInfoProvider } from '@pn/services/api/intel/apiCompanyInfoProvider';
import { isNil } from 'lodash-es';
import React from 'react';

export async function getCompanyExtraInfo(
  companyStockSymbol: Company['stockSymbol']
) {
  try {
    extraCompanyInfoActions().request();

    const company =
      await apiCompanyInfoProvider.getCompanyExtraInfo(companyStockSymbol);

    extraCompanyInfoActions().receive(company);
  } catch (error) {
    handleError({
      error,
      onError: extraCompanyInfoActions().error,
      userFriendlyMessage: 'Failed to get company info',
    });
  }
}

export function useAutoLoadSelectedCompany() {
  const { selectedCompany } = useCompaniesStorage();
  const companyStockSymbolToFetch = selectedCompany?.stockSymbol;

  React.useEffect(() => {
    if (!isNil(companyStockSymbolToFetch)) {
      getCompanyExtraInfo(companyStockSymbolToFetch);
    } else {
      extraCompanyInfoActions().reset();
    }
  }, [companyStockSymbolToFetch]);
}
