import { dependencies } from '@pn/core/dependencies';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { isEmpty, isNil } from 'lodash-es';
import { intelReportsSlice, IntelReportsState } from './intelReportsSlice.ts';
import type { IReportsActions, IReportsStorage } from './ports';
import { createSelector } from '@reduxjs/toolkit';
import { IntelActivityItem } from '@pn/core/domain/intel/intel-activity-item.ts';
import { IntelReport } from '@pn/core/domain/intel/intel-report.ts';

export const useReduxIntelReportsStorage = (): IReportsStorage => {
  const { name, getInitialState } = intelReportsSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isFetching: useSliceSelector((state) => state.isFetching),
    isReportActive: useSliceSelector(
      (state) => !isEmpty(state.selectedReportId)
    ),
    isEditingReport: useSliceSelector((state) => state.isEditingReport),
    reports: useSliceSelector((state) => state.reports),
    pinnedReports: useSliceSelector(selectPinnedReports),
    selectedReport: useSliceSelector(selectSelectedReport),
    selectedReportWithData: useSliceSelector(selectSelectedReportWithData),
    selectedReportData: useSliceSelector(selectSelectedReportData),
    selectedReportId: useSliceSelector((state) => state.selectedReportId),
    licences: useSliceSelector(selectLicenses),
    spuds: useSliceSelector(selectSpuds),
    reportToLoad: useSliceSelector(selectReportToLoad),
  };
};

export const reduxIntelReportsActions = (): IReportsActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = intelReportsSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    request: () => {
      dispatch(actions.request());
    },
    receive: (reports) => {
      dispatch(actions.receive(reports));
    },
    toggleIsEditingReport: () => {
      dispatch(actions.toggleIsEditingReport());
    },
    create: (params) => {
      dispatch(actions.create(params));
    },
    update: (reportId, updatedReport) => {
      dispatch(actions.update({ reportId, updatedReport }));
    },
    updateEmailSchedule(
      reportId: IntelReport['id'],
      emailFrequency: IntelReport['emailSchedule']
    ) {
      dispatch(actions.updateEmailSchedule({ reportId, emailFrequency }));
    },
    updateIsPinned(reportId: IntelReport['id'], isPinned: boolean) {
      dispatch(actions.updateIsPinned({ reportId, isPinned }));
    },
    saveSharedReport: (oldReportId: string, savedReport: IntelReport) => {
      dispatch(actions.saveSharedReport({ oldReportId, savedReport }));
    },
    setReports: (reports) => {
      dispatch(actions.setReports(reports));
    },
    selectReport: (id) => {
      dispatch(actions.selectReport(id));
    },
    clearSelectedReport: () => {
      dispatch(actions.clearSelectedReport());
    },
    remove(reportId: IntelReport['id']) {
      dispatch(actions.remove(reportId));
    },
    setProduction(reportId, production) {
      dispatch(actions.setProduction({ reportId, production }));
    },
    setMonthlyProduction: (reportId, monthlyProduction) => {
      dispatch(actions.setMonthlyProduction({ reportId, monthlyProduction }));
    },
    setYearOverYearData: (
      reportId,
      spudsYearOverYear,
      licencesYearOverYear
    ) => {
      dispatch(
        actions.setYearOverYearData({
          reportId,
          spudsYearOverYear,
          licencesYearOverYear,
        })
      );
    },
    setMonthlyLicences(reportId, monthlyLicences: IntelActivityItem[]) {
      dispatch(actions.setMonthlyLicences({ reportId, monthlyLicences }));
    },
    setMonthlySpuddedLicenses: (reportId, monthlySpuddedLicenses) => {
      dispatch(
        actions.setMonthlySpuddedLicenses({ reportId, monthlySpuddedLicenses })
      );
    },
    setIsLoadingProduction: (reportId) => {
      dispatch(actions.setIsLoadingProduction(reportId));
    },
    setIsLoadingWells(reportId) {
      dispatch(actions.setIsLoadingWells(reportId));
    },
    markReportAsLoaded(id: IntelReport['id']) {
      dispatch(actions.markReportAsLoaded(id));
    },
    unselectReport() {
      dispatch(actions.unselectReport());
    },
  };
};

const selectPinnedReports = createSelector(
  [(state: IntelReportsState) => state.reports],
  (reports) => reports.filter((r) => r.isPinned)
);

const selectSelectedReport = createSelector(
  [
    (state: IntelReportsState) => state.reports,
    (state: IntelReportsState) => state.selectedReportId,
  ],
  (reports, selectedReportId) =>
    reports.find((c) => selectedReportId.includes(c.id))
);

const selectSelectedReportWithData = createSelector(
  [
    (state: IntelReportsState) => state.reports,
    (state: IntelReportsState) => state.reportsData,
    (state: IntelReportsState) => state.selectedReportId,
  ],
  (reports, reportsData, selectedReportId) => {
    const report = reports.find((c) => selectedReportId.includes(c.id));

    const reportData = reportsData.find((c) =>
      selectedReportId.includes(c.reportId)
    );

    if (isNil(report) || isNil(reportData)) return;
    return { ...report, ...reportData };
  }
);

const selectSelectedReportData = createSelector(
  [
    (state: IntelReportsState) => state.reportsData,
    (state: IntelReportsState) => state.selectedReportId,
  ],
  (reportsData, selectedReportId) => {
    const reportData = reportsData.find((c) =>
      selectedReportId.includes(c.reportId)
    );

    if (isNil(reportData)) return;
    return reportData;
  }
);

const selectLicenses = createSelector(
  [
    (state: IntelReportsState) => state.reportsData,
    (state: IntelReportsState) => state.selectedReportId,
  ],
  (reportsData, selectedReportId) => {
    const reportData = reportsData.find((c) =>
      selectedReportId.includes(c.reportId)
    );

    if (isNil(reportData)) return [];
    return (reportData.monthlySpuddedLicenses ?? []).filter(
      (c) => c['licence_date'] === 'licence'
    );
  }
);

const selectSpuds = createSelector(
  [
    (state: IntelReportsState) => state.reportsData,
    (state: IntelReportsState) => state.selectedReportId,
  ],
  (reportsData, selectedReportId) => {
    const reportData = reportsData.find((rd) =>
      selectedReportId.includes(rd.reportId)
    );
    if (isNil(reportData)) return [];
    return reportData.monthlyLicenses ?? [];
  }
);

const selectReportToLoad = createSelector(
  [
    (state: IntelReportsState) => state.reports,
    (state: IntelReportsState) => state.reportsData,
    (state: IntelReportsState) => state.selectedReportId,
    (state: IntelReportsState) => state.loadedReportIds,
  ],
  (reports, reportsData, selectedReportId, loadedReportIds) => {
    const loadingReport = reportsData.find((r) => r.isLoading);
    if (!isNil(loadingReport)) return; // prevents loading multiple reports at a time
    return reports.find(
      (r) => selectedReportId.includes(r.id) && !loadedReportIds.includes(r.id)
    );
  }
);
