import { IApiIntelAlertMapper } from '@pn/core/mappers/intel';
import { isNil } from 'lodash-es';

export const apiIntelAlertsMapper: IApiIntelAlertMapper = {
  toDomainIntelAlert: (apiIntelAlert) => {
    if (isNil(apiIntelAlert)) {
      throw new Error('apiIntelAlert is not a valid object');
    }
    return {
      id: apiIntelAlert.id,
      userId: apiIntelAlert.user_id,
      annotationId: apiIntelAlert.annotation_id,
      triggerType: apiIntelAlert.trigger_type,
      startDate: apiIntelAlert.start_date,
      notificationPeriodDays: apiIntelAlert.notification_period_days,
      enabled: apiIntelAlert.enabled,
      createdAt: apiIntelAlert.created_at,
      updatedAt: apiIntelAlert.updated_at,
      sendIfNoActivity: apiIntelAlert.send_if_no_activity,
    };
  },
};
