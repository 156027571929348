import { handleError } from '@pn/core/errors/handleError';
import {
  companiesActions,
  useCompaniesStorage,
} from '@pn/core/storage';
import { apiCompanyProvider } from '@pn/services/api/company/apiCompanyProvider';
import { isEmpty } from 'lodash-es';
import React from 'react';

export async function getCompanies() {
  try {
    companiesActions().request();

    const companies = await apiCompanyProvider.getCompanies();

    companiesActions().receive(companies);
  } catch (error) {
    handleError({
      error,
      onError: companiesActions().error,
      userFriendlyMessage: 'Failed to get companies',
    });
  }
}

export function useAutoLoadCompanies() {
  const { companies } = useCompaniesStorage(); // should never be empty if properly loaded

  React.useEffect(() => {
    if (isEmpty(companies)) getCompanies();
  }, [companies]);
}
