import {
  buildIntelQueryObject,
  createIntelQuery,
} from '@pn/core/domain/intel/intel-activity-item';
import { isNil } from 'lodash-es';
import { Filter } from '@pn/core/domain/query.ts';
import { ReportFiltersAndId } from 'src/intel-templates/activityOverview.ts';

export function generateWellMismatches({
  startDate,
  endDate,
  formation,
  companyBrandName,
  fieldName,
  geoJSONGeometry,
  linkedReportId,
}: ReportFiltersAndId) {
  const mismatchesFilters: Filter[] = [
    {
      field: 'licenses.license_date',
      operator: '>=',
      value: startDate,
      unitSystem: undefined,
    },
    {
      field: 'licenses.license_date',
      operator: '<=',
      value: endDate,
      unitSystem: undefined,
    },
  ];

  if (!isNil(geoJSONGeometry)) {
    mismatchesFilters.push({
      field: 'wellbores.geom',
      operator: 'intersects',
      value: geoJSONGeometry as any,
      unitSystem: undefined,
    });
  }

  if (!isNil(formation)) {
    mismatchesFilters.push({
      field: 'wellbores.formation',
      operator: 'contains',
      value: formation,
      unitSystem: undefined,
    });
  }
  if (!isNil(companyBrandName)) {
    mismatchesFilters.push({
      field: 'company_brand.display_name',
      operator: '=',
      value: companyBrandName,
      unitSystem: undefined,
    });
  }

  if (!isNil(fieldName)) {
    mismatchesFilters.push({
      field: 'wellbores.field_name',
      operator: 'ILIKE',
      value: fieldName,
      unitSystem: undefined,
    });
  }

  return [
    createIntelQuery({
      name: 'Well Mismatches',
      query: buildIntelQueryObject('well_licence_mismatches', {
        filters: mismatchesFilters,
      }),
      dataType: 'wells',
      linkedReportId,
    }),
  ];
}
