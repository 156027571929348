import { companiesSlice } from '@pn/core/storage/company/companiesSlice';
import { companyDocumentsSlice } from '@pn/core/storage/intel/companyDocumentsSlice';
import { companyExtraInfoSlice } from '@pn/core/storage/intel/companyExtraInfoSlice';
import { companyQuarterlyStatsSlice } from '@pn/core/storage/intel/companyQuarterlyStatsSlice';
import { realTimeStockSlice } from '@pn/core/storage/intel/realTimeStockSlice';
import { createReduxStore } from '@pn/services/redux/store';
import { formationsSlice } from 'src/storage/formations/formationsSlice';
import { intelQueriesSlice } from 'src/storage/intel-query/intelQueriesSlice';
import { companyKPIsSlice } from 'src/storage/kpi/companyKPIsSlice';
import { mergersAndAcquisitionsSlice } from 'src/storage/mergers-and-acquisitions/mergersAndAcquisitionsSlice';
import { companyMonthlyStatsSlice } from 'src/storage/monthly-data/companyMonthlyStatsSlice';
import { intelAlertsSlice } from '@pn/core/storage/intel/intelAlertsSlice.ts';
import { intelReportsSlice } from './report/intelReportsSlice.ts';

export const store = createReduxStore({
  [formationsSlice.name]: formationsSlice.reducer,
  [intelQueriesSlice.name]: intelQueriesSlice.reducer,
  [companyKPIsSlice.name]: companyKPIsSlice.reducer,
  [mergersAndAcquisitionsSlice.name]: mergersAndAcquisitionsSlice.reducer,
  [companyMonthlyStatsSlice.name]: companyMonthlyStatsSlice.reducer,
  [intelReportsSlice.name]: intelReportsSlice.reducer,

  /* Core */
  [companiesSlice.name]: companiesSlice.reducer,
  [companyDocumentsSlice.name]: companyDocumentsSlice.reducer,
  [companyExtraInfoSlice.name]: companyExtraInfoSlice.reducer,
  [companyQuarterlyStatsSlice.name]: companyQuarterlyStatsSlice.reducer,
  [realTimeStockSlice.name]: realTimeStockSlice.reducer,
  [intelAlertsSlice.name]: intelAlertsSlice.reducer,
});
