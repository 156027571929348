import { handleError } from '@pn/core/errors/handleError';
import { quarterlyStatsActions } from '@pn/core/storage';
import { apiCompanyInfoProvider } from '@pn/services/api/intel/apiCompanyInfoProvider';
import React from 'react';

export async function getQuarterlyStats() {
  try {
    quarterlyStatsActions().request();
    const getExternalLayerSections =
      await apiCompanyInfoProvider.getQuarterlyStatistics();
    quarterlyStatsActions().receive(getExternalLayerSections);
  } catch (error) {
    handleError({
      error,
      onError: quarterlyStatsActions().error,
      userFriendlyMessage: 'Failed to get quarterly stats',
    });
  }
}

export function useAutoLoadIntelStatistics() {
  React.useEffect(() => {
    getQuarterlyStats();
  }, []);
}
