import { dependencies } from '@pn/core/dependencies';
import {
  getQueriesForProcessing,
  getQueriesToAddOrUpdate,
  getQueriesToRemove,
} from '@pn/core/domain/intel/intel-activity-item';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { createSelector } from '@reduxjs/toolkit';
import { isNil, some } from 'lodash-es';
import { intelQueriesSlice, IntelQueriesState } from './intelQueriesSlice';
import type { IIntelQueriesActions, IIntelQueriesStorage } from './ports';

export const useReduxIntelQueriesStorage = (): IIntelQueriesStorage => {
  const { name, getInitialState } = intelQueriesSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    areaFilterWorkspaceItemId: useSliceSelector(
      (state) => state.areaFilterWorkspaceItemId
    ),
    areaGeometryFilter: useSliceSelector((state) => state.areaGeometryFilter),
    provinceFilter: useSliceSelector((state) => state.provinceFilter),
    queries: useSliceSelector((state) => state.queries),
    mappableQueries: useSliceSelector(selectIntelMappableQueries),
    selectedQuery: useSliceSelector((state) =>
      state.queries.find((query) => query.id === state.selectedQueryId)
    ),
    queriesToProcess: useSliceSelector(selectIntelQueriesToProcess),
    queriesToAddOrUpdateOnMap: useSliceSelector(
      selectIntelQueriesToAddOrUpdateOnMap
    ),
    queriesToRemoveFromMap: useSliceSelector(selectIntelQueriesToRemoveFromMap),
    isFetching: useSliceSelector(selectIntelQueriesThatAreFetching),
    ids: useSliceSelector(selectIntelQueriesIds),
  };
};

export const reduxIntelQueriesActions = (): IIntelQueriesActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = intelQueriesSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    request: (id) => dispatch(actions.request(id)),
    receive: (id, items) => dispatch(actions.receive({ id, items })),
    error: (id) => dispatch(actions.error({ id })),
    retry: (id) => dispatch(actions.retry(id)),
    select: (id) => dispatch(actions.select(id)),
    unselect: () => dispatch(actions.unselect()),
    create: (query) => dispatch(actions.create(query)),
    add: (queries) => dispatch(actions.add(queries)),
    update: (updatedQuery) => dispatch(actions.update(updatedQuery)),
    updateQuery: (id, query) => dispatch(actions.updateQuery({ id, query })),
    updateName: (id, name) => dispatch(actions.updateName({ id, name })),
    updateFilter: (id, filter) =>
      dispatch(actions.updateFilter({ id, filter })),
    updateFilters: (id, filters) =>
      dispatch(actions.updateFilters({ id, filters })),
    updateSorts: (id, sorts) => dispatch(actions.updateSorts({ id, sorts })),
    removeFilterByField: (id, field) =>
      dispatch(actions.removeFilterByField({ id, field })),
    remove: (id) => dispatch(actions.remove(id)),
    toggleMapping: (id) => dispatch(actions.toggleMapping(id)),
    toggleHideFromWorkspace: (id) =>
      dispatch(actions.toggleHideFromWorkspace(id)),
    checkAll: (id) => dispatch(actions.checkAll(id)),
    uncheckAll: (id) => dispatch(actions.uncheckAll(id)),
    check: (id, intelDataId) => dispatch(actions.check({ id, intelDataId })),
    uncheck: (id, intelDataId) =>
      dispatch(actions.uncheck({ id, intelDataId })),
    clearAll: () => dispatch(actions.clearAll()),
    clearWorkspace: () => dispatch(actions.clearWorkspace()),
    setCheckedIds: (id, checkedIds) =>
      dispatch(actions.setCheckedIds({ id, checkedIds })),
    markAllQueriesForVisualProcessing: () =>
      dispatch(actions.markAllQueriesForVisualProcessing()),
    markAsVisuallyProcessed: (id: string) =>
      dispatch(actions.markAsVisuallyProcessed(id)),
    updateAreaFilter: (areaGeoJSON, workspaceItemId) =>
      dispatch(
        actions.updateAreaFilter({ geometry: areaGeoJSON, workspaceItemId })
      ),
    updateProvinceFilter: (province: string | null) =>
      dispatch(actions.updateProvinceFilter(province)),
    clearGlobalFilters: () => dispatch(actions.clearGlobalFilters()),
    removeAllFromMap: () => dispatch(actions.removeAllFromMap()),
    removeFromMap: (id) => dispatch(actions.removeFromMap(id)),
    removeByReportId: (reportId: string) =>
      dispatch(actions.removeByReportId(reportId)),
  };
};

const selectIntelMappableQueries = createSelector(
  [(state: IntelQueriesState) => state.queries],
  (queries) => queries.filter((query) => !query.hideFromWorkspace)
);

const selectIntelQueriesToProcess = createSelector(
  [(state: IntelQueriesState) => state.queries],
  (queries) => getQueriesForProcessing(queries)
);

const selectIntelQueriesToAddOrUpdateOnMap = createSelector(
  [(state: IntelQueriesState) => state.queries],
  (queries) => getQueriesToAddOrUpdate(queries)
);

const selectIntelQueriesToRemoveFromMap = createSelector(
  [(state: IntelQueriesState) => state.queries],
  (queries) => getQueriesToRemove(queries)
);

const selectIntelQueriesThatAreFetching = createSelector(
  [(state: IntelQueriesState) => state.queries],
  (queries) => some(queries, (query) => query.isFetching)
);
const selectIntelQueriesIds = createSelector(
  [(state: IntelQueriesState) => state.queries],
  (queries) => queries.map((query) => query.id)
);
