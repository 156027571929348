import {
  useCurrentUserStorage,
  workspaceActions,
} from '@pn/core/storage';
import { apiAnnotationProvider } from '@pn/services/api/annotation/apiAnnotationProvider';
import React from 'react';

export function useAutoGetIntelWorkspaceItems() {
  const { isUserLoaded } = useCurrentUserStorage();

  React.useEffect(() => {
    (async () => {
      if (!isUserLoaded) return;

      workspaceActions().updateIsFetching(true);

      const areaWorkspaceItems =
        await apiAnnotationProvider.getAnnotationAreaWorkspaceItems();

      workspaceActions().updateIsFetching(false);
      workspaceActions().add([...areaWorkspaceItems]);
    })();
  }, [isUserLoaded]);
}
