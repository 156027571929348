import { useIntelAccess } from '@pn/core/permissions/isIntelAccessDenied';
import React from 'react';
import { generateIntelQueriesForReport } from 'src/intel-templates/activityOverview.ts';
import {
  intelQueriesActions,
  intelReportsActions,
  useIntelReportsStorage,
} from 'src/storage';
import { pnApiClient } from '@pn/services/api/pnApiClient.ts';
import { isEmpty, isNil, orderBy } from 'lodash-es';
import {
  IntelReportData,
  processIntelReportFilters,
  UWIWithMonthsProduction,
} from '@pn/core/domain/intel/intel-report.ts';
import { useBOEIntel } from 'src/web-ui/contexts/useBOEIntel.tsx';
import { Filter } from '@pn/core/domain/query.ts';
import { apiIntelDynamicDataProvider } from '@pn/services/api/intel-data/apiIntelDataProvider.ts';
import {
  buildIntelQueryObject,
  createIntelQuery,
} from '@pn/core/domain/intel/intel-activity-item.ts';
import { useCompaniesStorage } from '@pn/core/storage';

export function useLoadReportData() {
  const access = useIntelAccess();
  const isAccessDeniedToReports = access('reports').denied();
  const { possibleLandSaleDates } = useBOEIntel();
  const { reportToLoad: report } = useIntelReportsStorage();
  const { companyNameToIdRecord } = useCompaniesStorage();

  React.useEffect(() => {
    if (!report || isEmpty(possibleLandSaleDates)) return;
    const reportId = report.id;
    intelReportsActions().markReportAsLoaded(report.id);
    intelQueriesActions().removeByReportId(report.id); // clears all existing queries for this report to refresh the data (used when editing)
    const intelQueriesForReport = generateIntelQueriesForReport(
      report,
      companyNameToIdRecord,
      possibleLandSaleDates[0]
    );

    intelQueriesActions().add(intelQueriesForReport);
    if (isAccessDeniedToReports) return; // no freeloaders allowed

    const {
      geoJSONGeometry,
      companyBrandName,
      formation,
      fieldName,
      startDate,
      endDate,
      productionMonthToConsider,
      historicalDataStartDate,
    } = processIntelReportFilters(report.filters, companyNameToIdRecord);

    const payload: any = {
      start_date: startDate,
      end_date: endDate,
    };
    const wellFilters = [];
    if (geoJSONGeometry) {
      payload.geo_shape = geoJSONGeometry;
    }
    if (companyBrandName) {
      payload.company_brand_name = companyBrandName;
      wellFilters.push({
        field: 'company_brand.display_name',
        operator: '=',
        value: companyBrandName,
        unitSystem: undefined,
      });
    }
    if (formation) {
      payload.formation = formation;
      wellFilters.push({
        field: 'formation',
        operator: 'contains',
        value: formation,
        unitSystem: undefined,
      });
    }
    if (fieldName) {
      payload.field_name = fieldName;
      wellFilters.push({
        field: 'field_name',
        operator: 'contains',
        value: fieldName,
        unitSystem: undefined,
      });
    }
    intelReportsActions().setIsLoadingWells(reportId);
    getWellData(
      [
        {
          field: 'last_spud_date',
          operator: '>=',
          value: historicalDataStartDate,
          unitSystem: undefined,
        },
        ...wellFilters,
      ],
      geoJSONGeometry
    ).then((monthlySpuddedLicenses) => {
      intelReportsActions().setMonthlySpuddedLicenses(
        reportId,
        monthlySpuddedLicenses
      );
    });
    getWellData(
      [
        {
          field: 'licenses.license_date',
          operator: '>=',
          value: historicalDataStartDate,
          unitSystem: undefined,
        },
        ...wellFilters,
      ],
      geoJSONGeometry
    ).then((monthlyLicenses) => {
      intelReportsActions().setMonthlyLicences(reportId, monthlyLicenses);
    });

    pnApiClient
      .request<any>({
        url: `v2/intel/report/wells_yoy`,
        method: 'POST',
        payload,
      })
      .then((report) => {
        intelReportsActions().setYearOverYearData(
          reportId,
          report.spudded_licences_count_yoy,
          report.licenced_count_yoy
        );
      });

    if (!isNil(productionMonthToConsider)) {
      intelReportsActions().setIsLoadingProduction(reportId);
      payload.production_month_considered = productionMonthToConsider;

      pnApiClient
        .request<{ production: IntelReportData['monthlyProduction'] }>({
          url: `v2/intel/report/production`,
          method: 'POST',
          payload,
        })
        .then(({ production }) => {
          intelReportsActions().setMonthlyProduction(
            reportId,
            orderBy(production, 'month') ?? []
          );
        });
      pnApiClient
        .request<any>({
          url: `v2/intel/report/months_production`,
          method: 'POST',
          payload,
        })
        .then((rawProductionMonthData) => {
          function processSSEChunk(chunk: string): UWIWithMonthsProduction[] {
            const lines = chunk.split('\n');
            const productionData: UWIWithMonthsProduction[] = [];
            for (const line of lines) {
              if (line.startsWith('data: ')) {
                const data = line.substring(6);
                try {
                  const parsedData = JSON.parse(data);
                  productionData.push(parsedData);
                  // Process the data as needed
                } catch (error) {
                  console.error('Error parsing data:', error);
                }
              }
            }
            return productionData;
          }

          intelReportsActions().setProduction(
            reportId,
            processSSEChunk(rawProductionMonthData)
          );
        });
    }
  }, [
    companyNameToIdRecord,
    isAccessDeniedToReports,
    possibleLandSaleDates,
    report,
  ]);
}

async function getWellData(
  filters: Filter[],
  geographyFilter: GeoJSON.Geometry | null | undefined
) {
  return apiIntelDynamicDataProvider.getData(
    createIntelQuery({
      name: 'Licence Activity',
      query: buildIntelQueryObject('well_licenses', {
        filters,
      }),
      dataType: 'wells',
    }),
    geographyFilter ?? null,
    null
  );
}
