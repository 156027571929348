import { Tab, Tabs } from '@mui/material';
import React from 'react';

type ContextType = {
  range: string;
  handleUpdateRange: (event: React.SyntheticEvent, value: any) => void;
};
const ranges = ['max', '5Y', '3Y', '1Y', '6M', '3M', '1M'];

export const YearAndQuarterRangeContext = React.createContext(
  {} as ContextType
);

export const useStockChartRange = () => {
  return React.useContext(YearAndQuarterRangeContext);
};

export const StockChartRangeStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [range, setRange] = React.useState(ranges[2]);

  const handleUpdateRange = React.useCallback(
    (_event: React.SyntheticEvent, value: any) => setRange(value),
    []
  );

  return (
    <YearAndQuarterRangeContext.Provider
      value={{
        range,
        handleUpdateRange,
      }}
    >
      {children}
    </YearAndQuarterRangeContext.Provider>
  );
};

export function StockChartRangeSelector() {
  const { range, handleUpdateRange } = useStockChartRange();

  return (
    <Tabs
      value={range}
      onChange={handleUpdateRange}
      aria-label="icon tabs example"
      variant="scrollable"
      scrollButtons={'auto'}
    >
      {ranges.map((range) => {
        return (
          <Tab key={range} value={range} aria-label={range} label={range} />
        );
      })}
    </Tabs>
  );
}
