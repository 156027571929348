import { handleError } from '@pn/core/errors/handleError';
import {
  intelAlertsActions,
  useCurrentUserStorage,
} from '@pn/core/storage';
import React from 'react';
import { apiIntelAlertsProvider } from '@pn/services/api/intel-alert/apiIntelAlertsProvider.ts';
import { useIntelAccess } from '@pn/core/permissions/isIntelAccessDenied.ts';

export async function getIntelAlerts() {
  try {
    intelAlertsActions().request();

    const alerts = await apiIntelAlertsProvider.getAlerts();

    intelAlertsActions().receive(alerts);
  } catch (error) {
    handleError({
      error,
      onError: intelAlertsActions().error,
      userFriendlyMessage: 'Failed to get stock info',
    });
  }
}

export function useAutoGetIntelAlerts() {
  const { isUserLoaded } = useCurrentUserStorage(); // needed here to get injected first
  const access = useIntelAccess();
  const accessDenied = access('alerts').denied();

  React.useEffect(() => {
    if (accessDenied || !isUserLoaded) return;
    getIntelAlerts();
  }, [isUserLoaded, accessDenied]);
}
