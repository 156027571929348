import { IIntelAlertsProvider } from '@pn/core/providers/intel/ports';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { ApiIntelAlert } from './types';
import { apiIntelAlertsMapper } from './apiIntelAlertsMapper.ts';
import { isEmpty } from 'lodash-es';

export const requestAlerts = async (userId?: string) => {
  let apiMonthlyAlerts: ApiIntelAlert[] = [];
  if (isEmpty(userId)) {
    apiMonthlyAlerts = await pnApiClient.request<ApiIntelAlert[]>({
      url: `v1/market_intel/alert_subscriptions`,
    });
  } else {
    // can do the admin version of the endpoint here? unsure
  }

  return apiMonthlyAlerts.map((alert) =>
    apiIntelAlertsMapper.toDomainIntelAlert(alert)
  );
};

export const apiIntelAlertsProvider: IIntelAlertsProvider = {
  getAlerts: requestAlerts,
};
