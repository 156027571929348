import { handleError } from '@pn/core/errors/handleError.ts';
import React from 'react';
import {
  intelReportsActions,
  mergersAndAcquisitionsActions,
} from '@pn/intel/src/storage';
import { apiIntelReportsProvider } from '@pn/services/api/intel-report/apiIntelReportsProvider.ts';
import { useCurrentUserStorage } from '@pn/core/storage';

export async function getIntelReports() {
  try {
    intelReportsActions().request();
    const reports = await apiIntelReportsProvider.getIntelReports();
    intelReportsActions().receive(reports);
  } catch (error) {
    handleError({
      error,
      onError: mergersAndAcquisitionsActions().error,
      userFriendlyMessage: 'Failed to get intel reports',
    });
  }
}

export function useAutoLoadIntelReports() {
  const { isUserLoaded } = useCurrentUserStorage();
  React.useEffect(() => {
    if (!isUserLoaded) return;
    getIntelReports();
  }, [isUserLoaded]);
}
