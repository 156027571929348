import { Company, IntelCompanyView } from '@pn/core/domain/intel/company';
import {
  generateQuarterlyStatisticViews,
  isMissingDetailedProduction,
} from '@pn/core/domain/intel/corporate-production';
import { CompanyKPI } from '@pn/core/domain/intel/intel';
import {
  convertToQuarterlyLicensedProductionView,
  generateMonthlyLicensedProductionView,
} from '@pn/core/domain/intel/monthly-licensed-production';
import { useIntelAccess } from '@pn/core/permissions/isIntelAccessDenied';
import {
  companiesActions,
  useCompaniesStorage,
  useQuarterlyStatsStorage,
  useWorkspaceStorage,
} from '@pn/core/storage';
import { apiCompanyMapper } from '@pn/services/api/company/apiCompanyMapper';
import { ApiCompany } from '@pn/services/api/company/types';
import { apiCompanyInfoProvider } from '@pn/services/api/intel/apiCompanyInfoProvider';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { isEmpty, isNil, orderBy } from 'lodash-es';
import React from 'react';
import { notificationService } from 'src/application/externalDependencies';
import { useAutoLoadProductionDataByCompanyIds } from 'src/providers/intel-data/getMonthlyProductionData';
import {
  useCompanyKPIsStorage,
  useIntelReportsStorage,
  useMonthlyStatsStorage,
} from 'src/storage';

type ExpandedCompanyView = IntelCompanyView & {
  color: string;
};

type BOEIntelType = {
  // until all the necessary data is loaded, this flag will be false
  isDataInitialized: boolean;
  // is fetching data that is loaded afterward and not needed immediately
  isFetchingExtraData: boolean;
  isLoadingCompany: boolean;
  selectedCompanyView: IntelCompanyView | undefined;
  companyViews: IntelCompanyView[];
  selectedCompanies: ExpandedCompanyView[];
  possibleLandSaleDates: string[];
  handleAddCompanyToList: (id: ExpandedCompanyView['id']) => void;
  handleRemoveCompanyFromList: (id: ExpandedCompanyView['id']) => void;
  handleSetSelectedCompanies: (ids: ExpandedCompanyView['id'][]) => void;
};

export const BOEIntelStateContext = React.createContext<BOEIntelType>({
  isFetchingExtraData: false,
  isLoadingCompany: false,
  isDataInitialized: false,
  selectedCompanyView: undefined,
  possibleLandSaleDates: [],
  companyViews: [],
  selectedCompanies: [],
  handleAddCompanyToList: () => {},
  handleRemoveCompanyFromList: () => {},
  handleSetSelectedCompanies: () => {},
});
// TODO overcomplicated, needs simplification
export const BOEIntelStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const access = useIntelAccess();
  const isFreeUser = access('exporting').denied();
  const [partialKPIs, setPartialKPIs] = React.useState<CompanyKPI[]>([]);
  const [possibleLandSaleDates, setPossibleLandSaleDates] = React.useState<
    string[]
  >([]);
  const { isFetching: isFetchingWorkspaceItems } = useWorkspaceStorage();

  const [isLoadingCompany, setIsLoadingCompany] = React.useState(false);

  React.useEffect(() => {
    if (isFreeUser) return;
    apiCompanyInfoProvider.getAllCompanyKPIs().then((partialKPIs) => {
      setPartialKPIs(partialKPIs);
    });
  }, [isFreeUser]);

  React.useEffect(() => {
    if (!isEmpty(possibleLandSaleDates)) return;
    apiCompanyInfoProvider
      .getLandSaleOfferDates()
      .then((possibleOfferDates) => {
        setPossibleLandSaleDates(possibleOfferDates);
      });
  }, [possibleLandSaleDates]);

  const {
    isFetching: isFetchingCompanies,
    companies,
    selectedCompany,
    selectedCompanyId,
  } = useCompaniesStorage();
  const { isFetching: isFetchingQuarterlyStats, stats: quarterlyStats } =
    useQuarterlyStatsStorage();
  const { isFetching: isFetchingMonthlyStats, stats: monthlyStats } =
    useMonthlyStatsStorage();
  const { kpis: fullyLoadedKpis, isFetching: isFetchingKPIs } =
    useCompanyKPIsStorage();
  const { isFetching: isFetchingReports } = useIntelReportsStorage();

  const [checkedCompanyIds, setCheckedCompanyIds] = React.useState<
    Company['id'][]
  >([]);

  const companyIdsSelected = React.useMemo(() => {
    if (selectedCompany) return [selectedCompany.id, ...checkedCompanyIds];
    return checkedCompanyIds;
  }, [checkedCompanyIds, selectedCompany]);

  useAutoLoadProductionDataByCompanyIds(companyIdsSelected); // will fetch only those that haven't been fetched yet

  // ASSUMPTION: when properly loaded, stats and companies should NEVER be empty so this is a good check
  const shouldNotGenerateViews = React.useMemo(
    () =>
      isEmpty(companies) ||
      isEmpty(quarterlyStats) ||
      isFetchingCompanies ||
      isFetchingQuarterlyStats,
    [companies, quarterlyStats, isFetchingCompanies, isFetchingQuarterlyStats]
  );

  const companyViews = React.useMemo<IntelCompanyView[]>(() => {
    if (shouldNotGenerateViews) return [];
    const views: IntelCompanyView[] =
      orderBy(
        companies.map((company) => {
          const corporateQuarterlyStats = generateQuarterlyStatisticViews(
            quarterlyStats.filter((stat) => stat.companyId === company.id)
          );

          const petroNinjaMonthlyStats = monthlyStats
            .filter((stat) => company.id === stat.companyId)
            .map((pnStat) => generateMonthlyLicensedProductionView(pnStat));

          const companyKPIs: CompanyKPI | undefined =
            fullyLoadedKpis.find(({ companyId }) => companyId === company.id) ??
            partialKPIs.find(({ companyId }) => companyId === company.id) ??
            undefined;

          return {
            ...company,
            corporateQuarterlyStats: orderBy(
              corporateQuarterlyStats,
              ['year', 'quarter'],
              'asc'
            ),
            petroNinjaMonthlyStats: orderBy(
              petroNinjaMonthlyStats,
              ['year', 'month'],
              'asc'
            ),
            petroNinjaQuarterlyStats: convertToQuarterlyLicensedProductionView(
              petroNinjaMonthlyStats
            ),
            isMissingDetailedProduction: isMissingDetailedProduction(
              corporateQuarterlyStats
            ),
            kpis: companyKPIs,
          };
        }),
        'name'
      ) ?? [];
    return views;
  }, [
    shouldNotGenerateViews,
    companies,
    quarterlyStats,
    monthlyStats,
    fullyLoadedKpis,
    partialKPIs,
  ]);

  const isDataInitializing =
    shouldNotGenerateViews ||
    isFetchingCompanies ||
    isFetchingQuarterlyStats ||
    isFetchingWorkspaceItems ||
    isFetchingReports;
  isEmpty(companyViews);

  React.useEffect(() => {
    if (
      !isDataInitializing &&
      isNil(selectedCompany) &&
      !isNil(selectedCompanyId)
    ) {
      setIsLoadingCompany(true);
      pnApiClient
        .request<ApiCompany | undefined>({
          url: 'v1/companies/' + selectedCompanyId,
        })
        .then((company) => {
          if (!isNil(company)) {
            const newCompany = apiCompanyMapper.toDomainCompany(company);
            companiesActions().load(newCompany);
          }
          setIsLoadingCompany(false);
        });
    }
  }, [isDataInitializing, selectedCompany, selectedCompanyId]);

  const selectedCompanies = React.useMemo(() => {
    return companyViews
      .filter(({ id }) => checkedCompanyIds.includes(id))
      .map((view, index) => ({
        ...view,
        color: getCompanyColor(index),
      }));
  }, [companyViews, checkedCompanyIds]);

  const handleAddCompanyToList = React.useCallback(
    (id: ExpandedCompanyView['id']) => {
      setCheckedCompanyIds((previousCheckedIds) => {
        if (previousCheckedIds.length >= 20) {
          notificationService.notify(
            'You can only compare up to 20 companies at a time.',
            'error'
          );
          return previousCheckedIds;
        }
        return [...previousCheckedIds, id];
      });
    },
    []
  );

  const handleRemoveCompanyFromList = React.useCallback(
    (id: ExpandedCompanyView['id']) => {
      setCheckedCompanyIds((previousCheckedIds) =>
        previousCheckedIds.filter((checkedId) => checkedId !== id)
      );
    },
    []
  );

  const handleSetSelectedCompanies = React.useCallback(
    (ids: ExpandedCompanyView['id'][]) => {
      setCheckedCompanyIds(ids);
    },
    []
  );

  const selectedCompanyView = React.useMemo(() => {
    return companyViews.find(({ id }) => id === selectedCompany?.id);
  }, [companyViews, selectedCompany]);

  return (
    <BOEIntelStateContext.Provider
      value={{
        isDataInitialized: !isDataInitializing,
        isFetchingExtraData: isFetchingMonthlyStats || isFetchingKPIs,
        isLoadingCompany,
        selectedCompanyView,
        companyViews,
        selectedCompanies,
        possibleLandSaleDates,
        handleAddCompanyToList,
        handleRemoveCompanyFromList,
        handleSetSelectedCompanies,
      }}
    >
      {children}
    </BOEIntelStateContext.Provider>
  );
};

export const useBOEIntel = () => {
  return React.useContext(BOEIntelStateContext);
};

const muiColors = [
  '#ffa000', //yellow
  '#f44336', //red
  '#03a9f4', //light blue
  '#1B5E20', //dark green
  // '#fbc02d', //yellow
  '#9c27b0', //purple
  '#009688', //teal
  '#1565c0', //dark blue
  '#ff5722', //orange
  '#795548', //brown
  '#607d8b', //blue grey
  '#673ab7', //deep purple
  '#e91e63', //pink
  '#4caf50', //green
  '#cddc39', //lime
  '#00bcd4', //cyan
  '#3f51b5', //indigo
  '#8bc34a', //light green
  '#2196f3', //blue
  '#ff9800', //deep orange
  '#9e9e9e', //grey
];

// generate hsl color based on index
function getCompanyColor(index: number) {
  if (index < muiColors.length) {
    return muiColors[index];
  }

  const hue = (index * 137.508) % 360;
  return `hsl(${hue}, 75%, 50%)`;
}
