import { AnnotationProvider } from '@pn/ui/annotations/AnnotationProvider';
import { ConfirmationDialogStateProvider } from '@pn/ui/context-components/ConfirmationDialog';
import { WorkspaceItemPanelProvider } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import React from 'react';
import { BOEIntelStateProvider } from './contexts/useBOEIntel.tsx';
import { StockChartRangeStateProvider } from './components/StockChartRange.tsx';
import { ActivityMapStateProvider } from './contexts/useActivityMap.tsx';
import { UIStateProvider } from './contexts/useUIState.tsx';

type ReactProvider = React.ComponentType<{ children: React.ReactNode }>;
type Props = {
  children: React.ReactNode;
};

export function ReactContextsProvider({ children }: Props) {
  const providers: ReactProvider[] = [
    WorkspaceItemPanelProvider,
    ConfirmationDialogStateProvider,
    BOEIntelStateProvider,
    StockChartRangeStateProvider,
    ActivityMapStateProvider,
    AnnotationProvider,
    UIStateProvider,
  ];

  return composeProviders(providers, children);
}

function composeProviders(
  providers: ReactProvider[],
  children: React.ReactNode
): React.ReactNode {
  return providers.reduceRight(
    (kids, Provider) => <Provider>{kids}</Provider>,
    children
  );
}
