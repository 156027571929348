import {
  isInteractableLayer,
  LayerStyle,
  LayerType,
} from '@pn/core/domain/layer';
import {
  generatePointsLayer,
  getHighlightedLayerId,
  getPointsLayerId,
  getSelectedLayerId,
  getSourceId,
} from '@pn/core/domain/workspace';
import { removeNilFields } from '@pn/core/utils/logic';
import { isValidColorString } from '@pn/services/utils/color';
import Color from 'color';
import { clamp, isArray, isNil, isString } from 'lodash-es';
import type { MapboxIMap } from '..';
import { POINTS_LAYER_MIN_ZOOM } from '../constants';
import { generateGeoJSONFeatureCollection } from '../mapboxUtils';
import {
  layerTypeMapping,
  mapboxLayerMapper,
} from '../mappers/mapboxLayerMapper';
import { mapboxLayerStyleMapper } from '../mappers/mapboxLayerStyleMapper';

export const addDataLayer: MapboxIMap['addDataLayer'] = function (
  this: MapboxIMap,
  layer,
  { beforeLayerIds, sourceLayer, style, selectionStyle }
) {
  if (this.hasLayer(layer.id)) {
    throw new Error(`Layer ${layer.id} exists already`);
  }

  const sourceId = getSourceId(layer.id);
  if (isNil(this._native.getSource(sourceId))) {
    if (isNil(layer.source)) throw new Error('Layer source must be defined');
    this._native.addSource(sourceId, layer.source);
  }

  /**
   * Add source item's vector tileset to allow for feature state updates.
   * This is necessary when visualizing a list before its source item was
   * visualized.
   */
  if (!isNil(sourceLayer)) {
    const sourceLayerSourceId = getSourceId(sourceLayer.id);
    if (
      sourceLayer.source.type === 'vector' &&
      isNil(this._native.getSource(sourceLayerSourceId))
    ) {
      this._native.addSource(sourceLayerSourceId, sourceLayer.source);
    }
  }

  const existingBeforeLayerId = beforeLayerIds.find((id) => this.hasLayer(id)); // TODO can probably simplify

  const mapboxStyle = mapboxLayerStyleMapper.toTargetLayerStyle(
    {
      ...style,
      opacity: isInteractableLayer(layer)
        ? ['case', isSelected, 0, style.opacity ?? 1]
        : (style.opacity ?? 1),
    },
    layer.type
  );

  this._native.addLayer(
    removeNilFields({
      id: layer.id,
      type: layerTypeMapping[layer.type],
      source: sourceId,
      'source-layer': layer.sourceLayer,
      minzoom: layer.renderAsPoints ? POINTS_LAYER_MIN_ZOOM : 0,
      ...mapboxStyle,
    }) as mapboxgl.AnyLayer,
    existingBeforeLayerId
  );

  if (isInteractableLayer(layer)) {
    const mapboxSelectionStyle = mapboxLayerStyleMapper.toTargetLayerStyle(
      {
        ...selectionStyle,
        opacity: ['case', isSelected, 1, 0],
        ...(layer.type === LayerType.Icon || layer.type === LayerType.Text
          ? { ignorePlacement: true } // prevents interference with the primary (i.e. non-selected) layer
          : {}),
      },
      layer.type
    );

    this._native.addLayer(
      removeNilFields({
        id: getSelectedLayerId(layer.id),
        type: layerTypeMapping[layer.type],
        source: sourceId,
        'source-layer': layer.sourceLayer,
        minzoom: layer.renderAsPoints ? POINTS_LAYER_MIN_ZOOM : 0,
        ...mapboxSelectionStyle,
      }) as mapboxgl.AnyLayer,
      existingBeforeLayerId
    );
  }

  if (layer.renderAsPoints) {
    const pointsSourceId = getSourceId(getPointsLayerId(layer.id));
    if (isNil(this._native.getSource(pointsSourceId))) {
      this._native.addSource(pointsSourceId, {
        type: 'geojson',
        data: generateGeoJSONFeatureCollection([]),
      });
    }

    const pointsLayer = generatePointsLayer(layer);
    this._native.addLayer(
      removeNilFields({
        ...mapboxLayerMapper.toTargetLayer(pointsLayer),
        source: pointsSourceId,
        maxzoom: POINTS_LAYER_MIN_ZOOM,
      }) as mapboxgl.AnyLayer,
      existingBeforeLayerId
    );

    this._native.addLayer(
      {
        id: getSelectedLayerId(getPointsLayerId(layer.id)),
        type: 'circle',
        source: pointsSourceId,
        paint: {
          'circle-color': selectionStyle.color as any,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#000',
          'circle-radius': 8,
          'circle-opacity': ['case', isSelected, 1, 0],
          'circle-stroke-opacity': ['case', isSelected, 1, 0],
        },
        maxzoom: POINTS_LAYER_MIN_ZOOM,
      },
      existingBeforeLayerId
    );
  }

  if (layer.type === LayerType.Polygon) {
    const mapboxStyle = mapboxLayerStyleMapper.toTargetLayerStyle(
      generateHighlightedStyle(layer.type, style),
      layer.type
    );

    this._native.addLayer(
      removeNilFields({
        id: getHighlightedLayerId(layer.id),
        type: layerTypeMapping[layer.type],
        source: sourceId,
        'source-layer': layer.sourceLayer,
        minzoom: layer.renderAsPoints ? POINTS_LAYER_MIN_ZOOM : 0,
        ...mapboxStyle,
      }) as mapboxgl.AnyLayer,
      existingBeforeLayerId
    );
  }
};

/**
 * Designed to process polygon layers. Lines are typically used as borders.
 */
function generateHighlightedStyle(
  layerType: LayerType,
  style: LayerStyle
): LayerStyle {
  switch (layerType) {
    case LayerType.Polygon:
      return {
        ...style,
        color: darkenColor(style.color),
        opacity: ['case', ['==', ['feature-state', 'hover'], true], 1, 0],
      };
    // case LayerType.Line:
    //   return {
    //     ...style,
    //     width: 1,
    //     opacity: ['case', ['==', ['feature-state', 'hover'], true], 1, 0],
    //   };
    default:
      return style;
  }
}

function darkenColor(color: unknown): unknown {
  if (!isArray(color)) {
    if (isString(color)) {
      const alpha = Color(color).alpha();
      if (alpha < 1) {
        return Color(color)
          .alpha(clamp(alpha + 0.25, 1))
          .rgb()
          .string();
      } else {
        return Color(color).darken(0.25).rgb().string();
      }
    } else {
      throw new Error('Invalid color argument');
    }
  }

  return color.map((c) => {
    if (isString(c) && isValidColorString(c)) {
      const alpha = Color(c).alpha();
      if (alpha < 1) {
        return Color(c)
          .alpha(clamp(alpha + 0.25, 1))
          .rgb()
          .string();
      } else {
        return Color(c).darken(0.25).rgb().string();
      }
    }

    return c;
  });
}

const isSelected = ['==', ['feature-state', 'isSelected'], true];
