import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IntelQuery } from '@pn/core/domain/intel/intel-activity-item';
import { Query } from '@pn/core/domain/query';
import { isEmpty } from 'lodash-es';

export type IntelQueriesState = {
  areaFilterWorkspaceItemId: string | null;
  areaGeometryFilter: GeoJSON.Geometry | null;
  provinceFilter: string | null;
  queries: IntelQuery[];
  fetching: IntelQuery['id'][];
  selectedQueryId: IntelQuery['id'] | undefined;
};

const initialState: IntelQueriesState = {
  areaFilterWorkspaceItemId: null,
  areaGeometryFilter: null,
  provinceFilter: null,
  queries: [],
  fetching: [],
  selectedQueryId: undefined,
};

export const createIntelQueriesSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      request: (state, action: PayloadAction<IntelQuery['id']>) => {
        const { payload: id } = action;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        if (index !== -1) {
          state.queries[index].isFetching = true;
          state.queries[index]._needsProcessing = false;
          state.queries[index].failedToFetch = false;
        }
      },
      receive: (
        state,
        action: PayloadAction<{
          id: IntelQuery['id'];
          items: IntelQuery['intelData'];
        }>
      ) => {
        const { payload } = action;
        const { id, items } = payload;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        if (index !== -1) {
          state.queries[index].intelData = items;
          state.queries[index].isFetching = false;
          if (state.queries[index].mapPlottingEnabled) {
            state.queries[index]._needsVisualProcessing = true;
            state.queries[index].checkedIntelDataIds = items.map(
              (item) => item.id
            );
          }
        }
      },
      error: (state, action: PayloadAction<{ id: IntelQuery['id'] }>) => {
        const { payload } = action;
        const { id } = payload;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        if (index !== -1) {
          state.queries[index].isFetching = false;
          state.queries[index].failedToFetch = true;
        }
      },
      retry: (state, action: PayloadAction<IntelQuery['id']>) => {
        const { payload } = action;

        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === payload
        );
        if (index !== -1) {
          state.queries[index]._needsProcessing = true;
        }
      },
      create: (state, action: PayloadAction<IntelQuery>) => {
        state.queries.push(action.payload);
      },
      add: (state, action: PayloadAction<IntelQuery[]>) => {
        state.queries = state.queries.concat(action.payload);
      },
      update: (state, action: PayloadAction<IntelQuery>) => {
        const { payload: updatedQuery } = action;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === updatedQuery.id
        );
        if (index !== -1) {
          state.queries[index] = updatedQuery;
          state.queries[index]._needsProcessing = true;
        }
      },
      select: (state, action: PayloadAction<IntelQuery['id']>) => {
        state.selectedQueryId = action.payload;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === action.payload
        );
        if (index !== -1) {
          state.queries[index]._avoidLoadingUntilVisible = false;
        }
      },
      unselect: (state) => {
        state.selectedQueryId = undefined;
      },
      updateQuery: (
        state,
        action: PayloadAction<{ id: string; query: Query }>
      ) => {
        const { payload } = action;
        const { id, query } = payload;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        if (index !== -1) {
          state.queries[index].query = query;
          state.queries[index]._needsProcessing = true;
        }
      },
      updateFilter: (
        state,
        action: PayloadAction<{ id: string; filter: Query['filters'][0] }>
      ) => {
        const { payload } = action;
        const { id, filter } = payload;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        if (index !== -1) {
          const filters = state.queries[index].query.filters;
          const filterIndex = filters.findIndex(
            (f) => f.field === filter.field
          );
          if (filterIndex !== -1) {
            filters[filterIndex] = filter;
          } else {
            filters.push(filter);
          }
          state.queries[index]._needsProcessing = true;
        }
      },
      updateFilters: (
        state,
        action: PayloadAction<{ id: string; filters: Query['filters'] }>
      ) => {
        const { payload } = action;
        const { id, filters } = payload;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        if (index !== -1) {
          state.queries[index].query.filters = filters;
          state.queries[index]._needsProcessing = true;
        }
      },
      updateSorts: (
        state,
        action: PayloadAction<{ id: string; sorts: Query['sorts'] }>
      ) => {
        const { payload } = action;
        const { id, sorts } = payload;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        if (index !== -1) {
          state.queries[index].query.sorts = sorts;
          // state.queries[index]._needsProcessing = true;
        }
      },
      removeFilterByField: (
        state,
        action: PayloadAction<{ id: string; field: string }>
      ) => {
        const { payload } = action;
        const { id, field } = payload;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        if (index !== -1) {
          state.queries[index].query.filters = state.queries[
            index
          ].query.filters.filter((filter) => filter.field !== field);
          state.queries[index]._needsProcessing = true;
        }
      },
      remove: (state, action: PayloadAction<IntelQuery['id']>) => {
        const { payload: id } = action;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        if (index !== -1) {
          state.queries.splice(index, 1);
        }
      },
      toggleMapping: (state, action: PayloadAction<IntelQuery['id']>) => {
        const { payload: id } = action;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        if (index !== -1) {
          state.queries[index].mapPlottingEnabled =
            !state.queries[index].mapPlottingEnabled;
          state.queries[index]._avoidLoadingUntilVisible = false;
        }
      },
      toggleHideFromWorkspace: (
        state,
        action: PayloadAction<IntelQuery['id']>
      ) => {
        const { payload: id } = action;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        const updatedHideFromWorkspace =
          !state.queries[index].hideFromWorkspace;
        if (index !== -1) {
          state.queries[index].hideFromWorkspace = updatedHideFromWorkspace;
          state.queries[index].mapPlottingEnabled = !updatedHideFromWorkspace;
          state.queries[index]._needsVisualProcessing = true;
        }
      },
      checkAll: (state, action: PayloadAction<IntelQuery['id']>) => {
        const { payload: id } = action;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        if (index !== -1) {
          state.queries[index].checkedIntelDataIds = state.queries[
            index
          ].intelData.map((item) => item.id);
          state.queries[index].mapPlottingEnabled = true;
          state.queries[index]._needsVisualProcessing = true;
        }
      },
      uncheckAll: (state, action: PayloadAction<IntelQuery['id']>) => {
        const { payload: id } = action;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        if (index !== -1) {
          state.queries[index].checkedIntelDataIds = [];
          state.queries[index].mapPlottingEnabled = false;
          state.queries[index]._needsVisualProcessing = true;
        }
      },
      check: (
        state,
        action: PayloadAction<{ id: IntelQuery['id']; intelDataId: string }>
      ) => {
        const { payload } = action;
        const { id, intelDataId } = payload;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        if (index !== -1) {
          state.queries[index].checkedIntelDataIds.push(intelDataId);
          state.queries[index].mapPlottingEnabled = true;
          state.queries[index]._needsVisualProcessing = true;
        }
      },
      uncheck: (
        state,
        action: PayloadAction<{ id: IntelQuery['id']; intelDataId: string }>
      ) => {
        const { payload } = action;
        const { id, intelDataId } = payload;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        if (index !== -1) {
          const newCheckedIds = state.queries[index].checkedIntelDataIds.filter(
            (id) => id !== intelDataId
          );
          state.queries[index].checkedIntelDataIds = newCheckedIds;
          state.queries[index]._needsVisualProcessing = true;
          if (isEmpty(newCheckedIds)) {
            state.queries[index].mapPlottingEnabled = false;
          }
        }
      },
      clearAll: (state) => {
        state.queries = [];
      },
      clearWorkspace: (state) => {
        state.queries = state.queries.filter(
          (query) => !isEmpty(query.linkedReportId)
        );
      },
      setCheckedIds: (
        state,
        action: PayloadAction<{ id: IntelQuery['id']; checkedIds: string[] }>
      ) => {
        const { payload } = action;
        const { id, checkedIds } = payload;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        if (index !== -1) {
          state.queries[index].checkedIntelDataIds = checkedIds;
          state.queries[index]._needsVisualProcessing = true;
          state.queries[index].mapPlottingEnabled = !isEmpty(checkedIds);
        }
      },
      markAsVisuallyProcessed: (
        state,
        action: PayloadAction<IntelQuery['id']>
      ) => {
        const { payload: id } = action;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        if (index !== -1) {
          state.queries[index]._needsVisualProcessing = false;
        }
      },
      updateAreaFilter: (
        state,
        action: PayloadAction<{
          geometry: GeoJSON.Geometry | null;
          workspaceItemId: string | null;
        }>
      ) => {
        state.areaGeometryFilter = action.payload.geometry;
        state.areaFilterWorkspaceItemId = action.payload.workspaceItemId;
        state.provinceFilter = null;
        state.queries.forEach((query) => {
          query._needsProcessing = true;
        });
      },
      updateProvinceFilter: (state, action: PayloadAction<string | null>) => {
        state.areaGeometryFilter = null;
        state.areaFilterWorkspaceItemId = null;
        state.provinceFilter = action.payload;
        state.queries.forEach((query) => {
          query._needsProcessing = true;
        });
      },
      clearGlobalFilters: (state) => {
        state.areaGeometryFilter = null;
        state.areaFilterWorkspaceItemId = null;
        state.provinceFilter = null;
        state.queries.forEach((query) => {
          query._needsProcessing = true;
        });
      },
      markAllQueriesForVisualProcessing: (state) => {
        state.queries.forEach((query) => {
          query._needsVisualProcessing = true;
        });
      },
      removeAllFromMap: (state) => {
        state.queries.forEach((query) => {
          query.mapPlottingEnabled = false;
          query._needsVisualProcessing = true;
        });
      },
      updateName: (
        state,
        action: PayloadAction<{ id: string; name: string }>
      ) => {
        const { payload } = action;
        const { id, name } = payload;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        if (index !== -1) {
          state.queries[index].name = name;
        }
      },
      removeFromMap: (state, action: PayloadAction<IntelQuery['id']>) => {
        const { payload: id } = action;
        const index = state.queries.findIndex(
          (query: IntelQuery) => query.id === id
        );
        if (index !== -1) {
          state.queries[index].mapPlottingEnabled = false;
          state.queries[index]._needsVisualProcessing = true;
        }
      },
      removeByReportId: (state, action: PayloadAction<string>) => {
        state.queries = state.queries.filter(
          (query) => query.linkedReportId !== action.payload
        );
      },
    },
  });

export const intelQueriesSlice = createIntelQueriesSlice('intelQueries');
