import { apiCompanyMapper } from './apiCompanyMapper';
import { ApiCompany } from './types';
import { pnApiClient } from '../pnApiClient';
import { ICompanyProvider } from '@pn/core/providers/company/ports';

export const requestIntelCompanies = async () => {
  const apiIntelCompanies = await pnApiClient.request<ApiCompany[]>({
    url: 'v1/companies',
  });

  return apiIntelCompanies.map((apiCompany) => {
    return apiCompanyMapper.toDomainCompany(apiCompany);
  });
};

export const requestCompaniesBySearchTerm = async (searchTerm: string) => {
  const apiIntelCompanies = await pnApiClient.request<ApiCompany[]>({
    url: 'v1/companies/find_by_name?search_term=' + searchTerm,
  });

  return apiIntelCompanies.map((apiCompany) => {
    return apiCompanyMapper.toDomainCompany(apiCompany);
  });
};

export const requestAllCompanies = async () => {
  const apiIntelCompanies = await pnApiClient.request<ApiCompany[]>({
    url: 'v1/companies?show_all=true',
  });

  return apiIntelCompanies.map((apiCompany) => {
    return apiCompanyMapper.toDomainCompany(apiCompany);
  });
};

export const apiCompanyProvider: ICompanyProvider = {
  getCompanies: requestIntelCompanies,
  getCompaniesBySearchTerm: requestCompaniesBySearchTerm,
  getAllCompanies: requestAllCompanies,
};
